import { TPromoBanner } from '@services/store-admin/types';
import crc32 from 'crc-32';
import { format } from 'date-fns';
/**
 * Item id generator
 * @param refValue
 * @returns
 * note: this is a dummy function
 */
export const generateItemId = (refValue?: string): number => {
  //const newId = new Date().getTime()
  return Math.abs(crc32.str(refValue ?? new Date().getTime().toString()));
};

export const isEmptyObject = (obj: object) => {
  if (obj === null || obj === undefined) return true;

  return JSON.stringify(obj).includes('{}');
};

export const emailToId = (email: string): number => {
  return Math.abs(crc32.str(email))
}

export const forEachPromise = (items: any, fn: any) => {
  return items.reduce(function (promise: any, item: any) {
      return promise.then(function () {
          return fn(item);
      });
  }, Promise.resolve());
}

export const countryDialCode = async(code: string) => {
    
    const dialCodes = await fetch('/api/countries-dial-codes.json').then((response) => response.json())

    //find the code
    const plusCode = dialCodes.find((dialCode: any) => {
        if (dialCode.code === code) {
            return dialCode;
        }
    });

    return plusCode?.dial_code;

}

export const hasPromo = (promo: TPromoBanner) => {

    const promoStartDate = new Date(format(promo.promo_start_date, 'yyyy-MM-dd'));
    const promoEndDate = new Date(format(promo.promo_end_date, 'yyyy-MM-dd'));
    
    const today = new Date();

    return today >= promoStartDate && today <= promoEndDate;    

}