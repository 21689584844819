import { DefaultImage } from '@components/product/default-image';
import { filterConfig, STONE_SAMPLE_THUMBNAILS } from './config';
import { TDiamond } from './types';
import _, { isEmpty, startCase } from 'lodash';
import { setUrl } from '@utils/url';
import { IItem } from '@contexts/store/store-types';
import { Jcrypto } from '@utils/crypto';
import http from '@framework/utils/http';

export class StoneHelper {
  static LOOSE_DIAMONDS_URL = 'loose-diamonds';

  static SHAPES = {
    RB: 'Round',
    PR: 'Princess',
    EM: 'Emerald',
    AS: 'Asscher',
    MQ: 'Marquise',
    CU: 'Cushion',
    RA: 'Radiant',
    PE: 'Pear',
    OV: 'Oval',
    HS: 'Heart',
    OC: 'Octagon',
  };

  static stoneBaseUrl = {
    diamond: 'loose-diamonds/',
    gemstone: 'gemstones/',
  };

  static getReportLink(lab: string, report: string) {
    const reportLink = {
      igi: `https://www.igi.org/verify-your-report/?r=${report}`,
      gia: `https://www.gia.edu/report-check?reportno=${report}`,
    };

    return reportLink[lab.toLowerCase()] ?? '';
  }

  static hasDiamondShape(shapeName: string) {
    return filterConfig.DiamondShapes[shapeName] !== undefined;
  }

  static shapeNameToPrefix(shapeName: string) {
    return filterConfig.DiamondShapes[shapeName] ?? '';
  }

  /**
   * Get Gemstone shape value
   * @param shape
   * @param reverse | if true, return shape name
   * @returns
   */
  static getGemShapeValue(shape: string, reverse: boolean = false) {
    if (reverse) {
      return (
        Object.keys(filterConfig.GemstoneShapes).find(
          (key) => filterConfig.GemstoneShapes[key] === shape,
        ) ?? ''
      );
    }

    return filterConfig.GemstoneShapes[shape] ?? '';
  }

  static getSampleThumbnail(shape: string) {
    return STONE_SAMPLE_THUMBNAILS[shape] ?? DefaultImage;
  }

  static pushState(basePath: string, url: string, lang: string = 'sg') {
    window.history.pushState(
      {},
      '',
      setUrl(this.stoneBaseUrl[basePath] + url.toLowerCase(), lang),
    );
  }

  static getParams = (searchParams: URLSearchParams) => {
    return new URLSearchParams(searchParams);
  };

  static getDiamondsFilterPaths = () => {
    const urlPath = window.location.pathname;
    let [main, loosediamond, shape] = urlPath.split('/');

    if (shape !== undefined) {
      shape = filterConfig.DiamondShapes[shape.toLowerCase()] ?? '';
    }

    return {
      main: main,
      loosediamond: loosediamond,
      shape: shape,
    };
  };

  static getColourDiamondsFilterPaths = (pathname: string) => {    
    let [main, loosediamond, fancy, shape] = pathname.split('/');

    if (shape !== undefined) {
      shape = filterConfig.DiamondShapes[shape.toLowerCase()] ?? '';
    }

    return {
      main: main,
      loosediamond: loosediamond,
      fancy: fancy,
      shape: shape,
    };
  };

  static getGemstoneFilterPaths = (pathname: string) => {
    
    const [main, gemstones, type, shape] = pathname.split('/');
    let color = undefined as string | undefined;

    if (type !== undefined) {
      const vtype = filterConfig.GemstoneTypes[type.toLowerCase()] ?? undefined;

      if (vtype === undefined) {
        color =
          filterConfig.GemstoneColorMarks[type.toLowerCase()] ?? undefined;
      }
    }

    if (shape !== undefined) {
      const vshape =
        filterConfig.GemstoneShapes[shape.toLowerCase()] ?? undefined;

      if (vshape === undefined) {
        color =
          filterConfig.GemstoneColorMarks[shape.toLowerCase()] ?? undefined;
      }
    }

    return {
      main: main,
      gemstones: gemstones,
      type: type,
      shape: shape,
      color: color,
    };
  };

  /**
   * product identifier
   */
  static productIdentifier(item: TDiamond) {
    return item?.lab == 'GEM'
      ? this.gemstoneIdentifier(item)
      : this.diamondIdentifier(item);
  }

  static productName(item: TDiamond): string {
    return item.lab == 'GEM' ? this.gemstoneName(item) : this.diamondName(item);
  }

  static getMainCategory(item: TDiamond): string {
    return item.lab == 'GEM' ? 'gemstones' : 'loose-diamonds';
  }

  /**
   * General product url
   */
  static productUrl(
    baseUrl: string,
    item: TDiamond,
    lang: string = 'sg',
  ): string {
    let productUrl = baseUrl;

    if (isEmpty(item)) return '';

    const vbaseUrl = this.getMainCategory(item);

    if (item.lab == 'GEM') {
      const shape = this.diamond(item.shape, 'shape').toLowerCase();
      const identifier = this.gemstoneIdentifier(item);

      productUrl = `/${vbaseUrl}/${item.stone_type}/${shape}/${identifier}`;
    } else {
      const shape = this.diamond(item.shape, 'shape').toLowerCase();
      const identifier = this.diamondIdentifier(item);
      productUrl = `/${vbaseUrl}/${shape}/${identifier}`;
    }

    productUrl = setUrl(productUrl, lang);

    return productUrl;
  }

  static diamondIdentifier(item: TDiamond) {
    if (['natural_coloured', 'labgrown_coloured'].indexOf(item.stone_type) > -1)
      return this.colouredIdentifier(item);

    let cut = item.cut ?? '';

    if (cut) {
      cut = _.capitalize(this.diamond(item?.cut ?? '', 'cut'));
      cut = cut.replace(' ', '-').replace('/', '') + '-cut-';
    }

    return `${item.carat}-carat-${item.color}-color-${item.clarity}-clarity-${cut}sku-${item.sku}`.toLowerCase();
  }

  static gemstoneIdentifier(item: TDiamond) {
    const shape = _.capitalize(this.diamond(item.shape, 'shape'));

    return `${item.carat}-carat-${shape}-sku-${item.sku}`.toLowerCase();
  }

  static colouredIdentifier(item: TDiamond) {
    const color = item.color.replace(' ', '-');

    return `${Number(item.carat)}-carat-${color}-color-${item.clarity}-clarity-sku-${item.sku}`.toLowerCase();
  }

  //0.80 carat Oval Natural Blue Sapphire
  static gemstoneName(item: TDiamond) {
    const shape = this.diamond(item.shape, 'shape');
    const color = item.color.replace('-', ' ').toLowerCase();
    const stoneType = item.stone_type;

    return `${item.carat} Carat ${startCase(shape)} ${startCase(stoneType)} ${startCase(color)} Gemstone`;
  }

  //Coloured diamond
  static colouredDiamondName(item: TDiamond) {
    const shape = this.diamond(item.shape, 'shape');
    const color = item.color
      .replace('-', ' ')
      .replace('fancy', ' ')
      .toLowerCase();

    const labLabel = item.stone_type.indexOf('labgrown') >= 0 ? 'Lab ' : '';

    return `${labLabel} ${item.carat} Carat ${startCase(shape)} ${startCase(color)} Diamond`;
  }

  /**
   * Set diamond name
   * @param $item array
   * @return string
   */
  static diamondName(item: TDiamond) {
    if (['natural_coloured', 'labgrown_coloured'].indexOf(item.stone_type) > -1)
      return this.colouredDiamondName(item);

    if (item.lab == 'GEM') {
      return this.gemstoneName(item);
    }

    const cut = startCase(this.diamond(item.cut ?? '', 'cut'));
    const polish = startCase(this.diamond(item.polish, 'polish'));
    const shape = startCase(this.diamond(item.shape, 'shape'));

    const cutLabel = ['RB', 'RD'].indexOf(item.shape)
      ? cut + ' Cut ' + shape
      : shape;
    const labLabel = item.stone_type.indexOf('labgrown') >= 0 ? 'Lab ' : '';

    return `${labLabel} ${item.carat} Carat ${startCase(item.color)} ${startCase(item.clarity)} ${polish} ${cutLabel} Diamond`;
  }

  // Diamond help info
  static diamond(prefix: string, feature: string = 'shape') {
    const shapeInfo = {
      RB: 'Round',
      RD: 'Round',
      PR: 'Princess',
      PRN: 'Princess',
      EM: 'Emerald',
      EC: 'Emerald',
      HS: 'Heart',
      HT: 'Heart',
      PE: 'Pear',
      CU: 'Cushion',
      RA: 'Radiant',
      OL: 'Oval',
      OV: 'Oval',
      AS: 'Asscher',
      AC: 'Asscher',
      ASH: 'Asscher',
      MQ: 'Marquise',
      OC: 'Octagon',
    };

    const cutInfo = {
      EX: 'Excellent',
      PH: 'Pure Hearts',
      VG: 'Very Good',
      G: 'Good',
      SIG: 'Signature Ideal',
      ID: 'Ideal',
      S: 'Step',
      SS: 'Step/Step',
      BS: 'Brilliant/Step',
    };
    const polishInfo = {
      F: 'Fair',
      G: 'Good',
      VG: 'Very Good',
      EX: 'Excellent',
    };
    const symmetryInfo = {
      F: 'Fair',
      FR: 'Fair',
      G: 'Good',
      VG: 'Very Good',
      EX: 'Excellent',
    };
    const culetInfo = {
      NONE: 'None',
      NON: 'None',
      N: 'None',
      M: 'Medium',
      SM: 'Small',
      VSM: 'Very Small',
    };
    const fluoresenceInfo = {
      NONE: 'None',
      NON: 'None',
      N: 'None',
      NN: 'None',
      F: 'Faint',
      FNT: 'Faint',
      Fnt: 'Faint',
      FAINT: 'Faint',
      VSL: 'Very Slight',
      M: 'Medium',
      MED: 'Medium',
      MEDIUM: 'Medium',
      SL: 'Slight',
      SLIGHT: 'Slight',
      S: 'Slight',
      ST: 'Strong',
      STRONG: 'Strong',
      VST: 'Very Strong',
      STG: 'Very Strong',
      Stg: 'Very Strong',
    };

    // Girdle Info
    const girdleInfo = {
      'VTN to MED': 'Very Thin to Medium',
      'VTN - MED': 'Very Thin to Medium',
      'VTN MED': 'Very Thin to Medium',
      VTN: 'Very Thin',
      'VTN - THK': 'Very Thin to Thick',
      'VTK to ETK': 'Very Thick  to Extremely Thick',
      'VTK - ETK': 'Very Thick  to Extremely Thick',
      'VTK ETK': 'Very Thick  to Extremely Thick',
      ETK: 'Extremely Thick',
      VTK: 'Very Thick',
      'THN to VTK': 'Thin to Very Thick',
      'THN VTK': 'Thin to Very Thick',
      'THN - VTK': 'Thin to Very Thick',
      'THN to THK': 'Thin to Thick',
      'THN - THK': 'Thin to Thick',
      'THN THK': 'Thin to Thick',
      'THN to STK': 'Thin to Slightly Thick',
      'THN STK': 'Thin to Slightly Thick',
      'THN - STK': 'Thin to Slightly Thick',
      'THN to MED': 'Thin to Medium',
      'THN MED': 'Thin to Medium',
      'THN - MED': 'Thin to Medium',
      'MED THN': 'Medium to Thin',
      'MED - THN': 'Medium to Thin',
      THN: 'Thin',
      'THK to VTK': 'Thick  to Very Thick',
      'THK - VTK': 'Thick  to Very Thick',
      'THK VTK': 'Thick  to Very Thick',
      'THK to ETK': 'Thick to Extremely Thick',
      'THK ETK': 'Thick to Extremely Thick',
      'THK - ETK': 'Thick to Extremely Thick',
      THK: 'Thick',
      'STK to VTK': 'Slightly Thick to Very Thick',
      'STK VTK': 'Slightly Thick to Very Thick',
      'STK - VTK': 'Slightly Thick to Very Thick',
      'STK to THK': 'Slightly Thick to Thick',
      'STK THK': 'Slightly Thick to Thick',
      'STK - THK': 'Slightly Thick to Thick',
      'STK to ETK': 'Slightly Thick to Extremely Thick',
      'STK ETK': 'Slightly Thick to Extremely Thick',
      STK: 'Slightly Thick',
      'MED to VTK': 'Medium to Very Thick',
      'MED VTK': 'Medium to Very Thick',
      'MED to THK': 'Medium to Thick',
      'MED THK': 'Medium to Thick',
      'MED - THK': 'Medium to Thick',
      'MED to STK': 'Medium to Slightly Thick',
      'MED STK': 'Medium to Slightly Thick',
      'MED - STK': 'Medium to Slightly Thick',
      'MED TO ETK': 'Medium to Extremely Thick',
      'MED - ETK': 'Medium to Extremely Thick',
      'MED ETK': 'Medium to Extremely Thick',
      MED: 'Medium',
      'MED - MED': 'Medium',
      'ETN TO THK': 'Extremely Thin to Thick',
      'ETN THK': 'Extremely Thin to Thick',
      'ETN - THK': 'Extremely Thin to Thick',
      'ETN to STK': 'Extremely Thin to Slightly Thick',
      'ETN STK': 'Extremely Thin to Slightly Thick',
      'ETN - STK': 'Extremely Thin to Slightly Thick',
    };

    const help: { [key: string]: { [key: string]: string } } = {
      shape: shapeInfo,
      cut: cutInfo,
      polish: polishInfo,
      symmetry: symmetryInfo,
      culet: culetInfo,
      fluorescence: fluoresenceInfo,
      girdle: girdleInfo,
    };

    if (help[feature] === undefined) return prefix;

    return help[feature][prefix] ?? prefix;
  }

  /**
   * Extract diamond, gemstones info
   * @param items
   * @returns
   */
  static getStoneItems(items: IItem[]) {
    let stoneItems = items.map((item) => {
      if (Object.hasOwn(item, 'productType') && item.productType === 'stone') {
        return item.productDetails;
      }
    });

    // remove undefined values
    stoneItems = stoneItems.filter((item) => item !== undefined);

    // get all stone vendor ids
    let stoneVendorIds = stoneItems.map((item) => {
      return item['Stone Prefix'];
    });

    // remove duplicate stone vendor ids
    stoneVendorIds = [...new Set(stoneVendorIds)];

    // get all stone items by stoneprefix
    const result = stoneVendorIds.map((stoneVendorId) => {
      const items = stoneItems.map((item) => {
        if (item['Stone Prefix'] === stoneVendorId) {
          return item['Stone Id'];
        }
      });

      return { [stoneVendorId]: items.join(',') };
    });

    return result;
  }

  // encrypt stone info
  static hideInfo(info: string) {
    return Jcrypto.encrypt(info);
  }

  static getProductWithPromoPrice = async (
    sku: string,
    storeId: number,
    price: number,
  ) => {
    const priceInfo = await http
      .get(
        `/loose-diamonds/with-promo?sku=${sku.toLowerCase()}&price=${price}&store_id=${storeId}`,
      )
      .then((res) => res.data);

    return await priceInfo.result;
  };
}
